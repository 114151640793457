import {resize} from '@common/observer';
import WindowEvents from "@common/WindowEvents";
import {getVal} from "@common/object";
import * as localStorage from "@common/localStorage";
import md5 from 'md5';



export default class Popup {
	#fullsizeChangeOn = 768;
	#backdropClass = 'popup_backdrop';
	#resizeObserver;
	#backdropToggler = true;
	#backdropEnabled = true;
	#keyboardEnabled = true;
	#subWindow = false;
	#bodyScrollEnabled = false;
	#popupId;

	/*--------------------------------------------------------------
	Опции для перетаскивания окна
	--------------------------------------------------------------*/
	#draggable = true;
	#dragOn = false;
	#startPos = {x:0,y:0};
	#lastPos;
	#dragElem;
	#saveDragPosition = false; //сохранение позиции перетаскивания в localStorage
	#storageKey = 'popupDrag'; //название кэша
	#savedPositions = {};
	#popupIdMd5;



	constructor(selector, settings) {
		settings = settings || {};

		this.el = document.querySelector(selector);

		if (this.el) {
			this.#popupId = this.el.id;

			this.positioning = typeof settings.positioning === 'boolean' ? (settings.positioning ? this.#fullsizeChangeOn : -1) : (typeof settings.positioning === 'number' ? settings.positioning : this.#fullsizeChangeOn);
			this.#fullsizeChangeOn = typeof settings.fullsizeChangeOn === 'number' ? settings.fullsizeChangeOn : this.#fullsizeChangeOn;
			this.#bodyScrollEnabled = typeof settings.bodyScrollEnabled === 'boolean' ? settings.bodyScrollEnabled : false;
			this.#backdropToggler = typeof settings.backdrop !== 'boolean' || !!settings.backdrop;
			this.#backdropEnabled = this.#backdropToggler && settings.backdrop !== 'disabled';
			this.#keyboardEnabled = settings.keyboard !== 'disabled';

			/*--------------------------------------------------------------
			Перетаскивание окна
			--------------------------------------------------------------*/
			this.#draggable = typeof settings.draggable === 'boolean' ? settings.draggable : false;
			this.#saveDragPosition = typeof settings.saveDragPosition === 'boolean' ? settings.saveDragPosition : false;
			if (this.#draggable && this.#saveDragPosition) this.#popupIdMd5 = md5(this.#popupId);

			/*--------------------------------------------------------------
			Окно в окне
			---------------------------------------------------------------*/
			this.#subWindow = typeof settings.subWindow === 'boolean' ? settings.subWindow : false;

			this.onBeforeShow = typeof settings.onBeforeShow === 'function' ? settings.onBeforeShow : null;
			this.onShow = typeof settings.onShow === 'function' ? settings.onShow : null;
			this.onBeforeHide = typeof settings.onBeforeHide === 'function' ? settings.onBeforeHide : null;
			this.onHide = typeof settings.onHide === 'function' ? settings.onHide : null;

			this.isShow = false;


			if (settings.fullSize === 'disabled') {
				this.el.classList.add('_fz-off');
				this.#fullsizeChangeOn = 0;
			}
			else if (settings.fullSize === 'enabled') {
				this.el.classList.add('_fz-on');
				this.positioning = -1;
			}

			if (this.#subWindow) {
				this.el.classList.add('_sub');
			}

			// this.#fixBootstrapFocusin(selector);
			this.#init();

		}
	}

	// #fixBootstrapFocusin = (selector) => {
	// 	/*--------------------------------------------------------------
	// 	Этот код необходим, потому что Bootstrap блокирует все вызовы
	// 	focusin от элементов вне диалогового окна.
	// 	---------------------------------------------------------------*/
	// 	document.addEventListener('focusin', (e) => {
	// 		if (e.target.closest(selector) !== null) {
	// 			e.stopImmediatePropagation();
	// 		}
	// 	});
	// };

	#init = () => {
		/*--------------------------------------------------------------
		Скрывать все элементы/окна кроме текущего по событию 'popup.hide'
		---------------------------------------------------------------*/
		this.el.addEventListener('popup.hide', () => {
			this.hide({
				backdropNeeded: true
			});
		});
	};

	/*--------------------------------------------------------------
	Отображение окна
	---------------------------------------------------------------*/
	show = () => {
		/*--------------------------------------------------------------
		Если не окно в окне (subWindow), то закрываем все открытые окна
		---------------------------------------------------------------*/
		if (!this.#subWindow) {
			document.querySelectorAll('.__popup').forEach((el) => {
				if (!this.el.isEqualNode(el)) {
					el.dispatchEvent(new CustomEvent('popup.hide'));
				}
			});
		}

		/*--------------------------------------------------------------
		Установка отступа справа если есть полоса прокрутки
		---------------------------------------------------------------*/
		this.#toggleBodyIndent(true);

		/*--------------------------------------------------------------
		Запуск обработчика до отображения окна
		---------------------------------------------------------------*/
		if (typeof this.onBeforeShow === 'function') this.onBeforeShow.call(this, this);

		/*--------------------------------------------------------------
		Отображение окна при помощи класса "__popup"
		---------------------------------------------------------------*/
		this.isShow = true;

		this.el.classList.add('__popup');

		/*--------------------------------------------------------------
		Отключение прокрутки экрана
		--------------------------------------------------------------*/
		if (!this.#bodyScrollEnabled) {
			// disableBodyScroll(document.body);

			document.body.style.overflow = 'hidden';
		}

		/*--------------------------------------------------------------
		Установка фона
		---------------------------------------------------------------*/
		if (this.#backdropToggler) {
			this.#setBackdrop();
		}

		/*--------------------------------------------------------------
		Установка позиции
		---------------------------------------------------------------*/
		if (this.positioning >= this.#fullsizeChangeOn) this.centeringPopup();

		/*--------------------------------------------------------------
		Установка наблюдателя за изменением размера окна
		---------------------------------------------------------------*/
		if (this.positioning > 0) {
			this.#resizeObserver = resize(this.el, (boxSize) => {
				if (this.#draggable && this.#dragElem && this.#lastPos) {
					this.#drag.setPosition();
				}
				else {
					this.centeringPopup(boxSize.width, boxSize.height);
				}

				this.#toggleBodyIndent(true);
			});
		}

		/*--------------------------------------------------------------
		Запуск обработчика после отображение окна
		---------------------------------------------------------------*/
		if (typeof this.onShow === 'function') this.onShow.call(this, this);

		/*--------------------------------------------------------------
		Добавление текущей страницы в историю при открытии модального окна
		---------------------------------------------------------------*/
		window.history.pushState(window.location.href, '', window.location.href);

		/*--------------------------------------------------------------
		При возврате "назад" закрывать модальное окно и оставаться на
		той же странице
		---------------------------------------------------------------*/
		window.addEventListener('popstate', this.hide, {once: false});

		/*--------------------------------------------------------------
		Событие изменения размера окна
		--------------------------------------------------------------*/
		WindowEvents.add('resize', this.#resizeEvent);

		/*--------------------------------------------------------------
		Скрывать по нажатию ESC === 27
		---------------------------------------------------------------*/
		if (this.#keyboardEnabled) {
			window.addEventListener('keyup', (e) => {
				if (e.key === 'Escape') {
					this.hide();
				}
			}, {once: false})
		}

		/*--------------------------------------------------------------
		Инициализация кнопки закрытия всплывающего окна
		---------------------------------------------------------------*/
		this.el.querySelectorAll('.close-btn').forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();
				this.hide();
			}, {once: true});
		});

		/*--------------------------------------------------------------
		Обнуление прокрутки окна при открытии
		--------------------------------------------------------------*/
		this.el.querySelectorAll('.w-body').forEach(el => {
			el.scrollTop = 0;
		});

		/*--------------------------------------------------------------
		Если включено перетаскивание окна
		--------------------------------------------------------------*/
		if (this.#draggable) {
			/*--------------------------------------------------------------
			Элемент, по которому можно перетаскивать
			--------------------------------------------------------------*/
			this.#dragElem = this.el.querySelector('.w-header');

			if (this.#dragElem) {
				this.#drag.getSavedPositions();

				if (!this.#lastPos) {
					this.#drag.resetPosition();
				}

				this.#dragElem.classList.add('_draggable');

				this.#dragElem.addEventListener('mousedown', this.#drag.start, {passive: true});
			}
		}
	};

	/*--------------------------------------------------------------
	Скрытие окна
	---------------------------------------------------------------*/
	hide = (options) => {
		if (this.isShow) {
			options = options || {};

			/*--------------------------------------------------------------
			Запуск обработчика перед закрытием окна
			---------------------------------------------------------------*/
			if (typeof this.onBeforeHide === 'function') this.onBeforeHide.call(this, this);

			/*--------------------------------------------------------------
			Если открыто окно subWindow, скрываем backdrop
			---------------------------------------------------------------*/
			if (getVal(this, 'backdrop.classList', '').includes('_sub')) {
				if (this.#subWindow) {
					this.el.classList.remove('__popup');
					this.backdrop.classList.add('_suboff');
					this.backdrop.classList.remove('_sub');

					if (!document.querySelectorAll('.__popup').length) {
						this.backdrop.remove();
						this.backdrop = null;

						this.#toggleBodyIndent(false);

						/*--------------------------------------------------------------
						Включение прокрутки окна
						--------------------------------------------------------------*/
						// enableBodyScroll(this.el);
						document.body.style.overflow = '';
					}

					setTimeout(() => {
						if (this.backdrop) {
							this.backdrop.classList.remove('_suboff');
						}
					}, 250);
				}
				else {
					return false;
				}
			}

			if (this.el) this.el.classList.remove('__popup');

			/*--------------------------------------------------------------
			Если не subWindow
			---------------------------------------------------------------*/
			if (!this.#subWindow) {
				options.backdropNeeded = typeof options.backdropNeeded === 'boolean' ? options.backdropNeeded : false;

				if (!options.backdropNeeded && this.backdrop) {
					this.backdrop.remove();
					this.backdrop = null;
				}

				this.#toggleBodyIndent(false);

				/*--------------------------------------------------------------
				Включение прокрутки окна
				--------------------------------------------------------------*/
				// enableBodyScroll(this.el);
				document.body.style.overflow = '';
			}

			this.isShow = false;

			/*--------------------------------------------------------------
			Запуск обработчика после закрытия окна
			---------------------------------------------------------------*/
			if (typeof this.onHide === 'function') this.onHide.call(this, this);

			/*--------------------------------------------------------------
			Отключение наблюдателя за изменением размера окна
			---------------------------------------------------------------*/
			if (this.#resizeObserver && this.positioning > 0) {
				this.#resizeObserver.disconnect();
			}

			/*--------------------------------------------------------------
			Удаление события dragStart
			--------------------------------------------------------------*/
			if (this.#draggable && this.#dragElem) {
				this.#dragElem.removeEventListener('mousedown', this.#drag.start);

				this.#dragElem = null;
			}

			/*--------------------------------------------------------------
			Удаление события изменения размера окна
			--------------------------------------------------------------*/
			WindowEvents.remove('resize', this.#resizeEvent);
		}
	};

	/*--------------------------------------------------------------
	Переключение отображения окна
	---------------------------------------------------------------*/
	toggle = (options = {}) => {
		options = typeof options === 'object' ? options : {};

		options.toggler = typeof options.toggler === 'boolean' ? options.toggler : !this.isShow;

		this.#fullsizeChangeOn = typeof options.fullsizeChangeOn === 'number' ? options.fullsizeChangeOn : this.#fullsizeChangeOn;

		if (typeof options.bodyScrollEnabled === 'boolean') this.#bodyScrollEnabled = options.bodyScrollEnabled;

		if (typeof options.onBeforeShow === 'function') this.onBeforeShow = options.onBeforeShow;
		if (typeof options.onShow === 'function') this.onShow = options.onShow;
		if (typeof options.onBeforeHide === 'function') this.onBeforeHide = options.onBeforeHide;
		if (typeof options.onHide === 'function') this.onHide = options.onHide;

		options.toggler ? this.show() : this.hide();
	};

	/*--------------------------------------------------------------
	Позиционирование окна по центру относительно его размеров
	---------------------------------------------------------------*/
	centeringPopup = () => {
		if (this.el) {
			this.el.removeAttribute('style');

			if (window.innerWidth >= this.#fullsizeChangeOn) {
				this.el.style.marginTop = -(this.el.offsetHeight / 2) + 'px';

				/*--------------------------------------------------------------
				Если ширина браузера больше ширины экрана
				(условно если браузер растянут на 2 монитора), то центрируем
				всплывающее окно относительно левого монитора
				--------------------------------------------------------------*/
				if (window.innerWidth > screen.width) {
					this.el.style.marginLeft = -(Math.abs(screen.width - window.innerWidth - this.el.offsetWidth) / 2) + 'px';
				}
				/*--------------------------------------------------------------
				Иначе обычное центрирование
				--------------------------------------------------------------*/
				else {
					this.el.style.marginLeft = -(this.el.offsetWidth / 2) + 'px';
				}
			}
		}
	};

	/*--------------------------------------------------------------
	Обработчик события изменения размера окна
	--------------------------------------------------------------*/
	#resizeEvent = () => {
		/*--------------------------------------------------------------
		Позиционировать открытое всплывающее окно при изменении размера окна
		---------------------------------------------------------------*/
		if (this.isShow && this.positioning >= this.#fullsizeChangeOn) {
			if (!this.#draggable) {
				this.centeringPopup();
			}
			else {
				if (this.#lastPos) {
					this.#drag.setPosition();
				}
				else {
					this.#drag.resetPosition();
				}
			}
		}

		/*--------------------------------------------------------------
		Установка отступа справа если есть полоса прокрутки
		---------------------------------------------------------------*/
		if (this.isShow) this.#toggleBodyIndent(true);
	};

	/*--------------------------------------------------------------
	Установка затемненного фона
	---------------------------------------------------------------*/
	#setBackdrop = () => {
		if (!document.querySelector('.' + this.#backdropClass)) {
			let div = document.createElement('div');
			div.classList.add(this.#backdropClass);
			document.body.append(div);

			this.backdrop = div;
		}
		else {
			this.backdrop = document.querySelector('.' + this.#backdropClass);
		}

		setTimeout(() => {
			if (this.backdrop) {
				this.backdrop.classList.add('show');

				if (this.#subWindow) {
					this.backdrop.classList.add('_sub');
				}
			}
		}, 0)

		if (this.#backdropEnabled) {
			this.backdrop.addEventListener('click', this.hide);
		}
	};

	/*--------------------------------------------------------------
	Установка отступа справа если есть полоса прокрутки, когда:
	body -> overflow: hidden
	---------------------------------------------------------------*/
	#toggleBodyIndent = toggler => {
		if (!toggler) {
			document.body.style.paddingRight = '';
			document.body.classList.remove('__sdbr');

			document.querySelectorAll('header').forEach((header) => {
				header.style.paddingRight = '';
				header.classList.remove('__hzi');
			});

			return;
		}

		if (document.body.style.overflow !== 'hidden') {
			let scrollbarWidth = window.innerWidth - document.body.clientWidth;

			if (!this.#bodyScrollEnabled && scrollbarWidth > 0) {
				document.body.style.paddingRight = scrollbarWidth + 'px';

				document.querySelectorAll('header').forEach((header) => {
					const hStyles = window.getComputedStyle(header);

					if (hStyles.position === 'fixed') {
						header.style.paddingRight = scrollbarWidth + 'px';
					}
				});
			}
			else {
				document.body.style.paddingRight = '';

				document.querySelectorAll('header').forEach((header) => {
					header.style.paddingRight = '';
				});
			}
		}
	};

	/*--------------------------------------------------------------
	Методы перетаскивания окна
	--------------------------------------------------------------*/
	#drag = (() => {
		return {
			/*--------------------------------------------------------------
			Инициализация событий
			--------------------------------------------------------------*/
			start: (event) => {
				if (window.innerWidth >= this.#fullsizeChangeOn) {
					this.#dragOn = true;
					this.#startPos = {x: event.clientX - this.el.offsetLeft, y: event.clientY - this.el.offsetTop};
					document.addEventListener('mousemove', this.#drag.setPosition);
					document.addEventListener('mouseup', this.#drag.end, {once: true});
				}
			},

			/*--------------------------------------------------------------
			Установка позиции окна
			--------------------------------------------------------------*/
			setPosition: (event) => {
				if (!this.#lastPos) {
					this.#lastPos = {};
				}

				let x = event ? event.clientX - this.#startPos.x : this.#lastPos.x || 0;
				let y = event ? event.clientY - this.#startPos.y : this.#lastPos.y || 0;

				// ограничиваем по горизонтали
				if (x < 0) {
					x = 0;
				}
				else if (x > window.innerWidth - this.el.offsetWidth) {
					x = window.innerWidth - this.el.offsetWidth;
				}

				// ограничиваем по вертикали
				if (y < 0) {
					y = 0;
				}
				else if (y > window.innerHeight - this.el.offsetHeight) {
					y = window.innerHeight - this.el.offsetHeight;
				}

				this.#lastPos.x = x;
				this.#lastPos.y = y;

				this.el.style.marginLeft = '';
				this.el.style.marginTop = '';
				this.el.style.left = x + 'px';
				this.el.style.top = y + 'px';
			},

			/*--------------------------------------------------------------
			Сброс и центрирование окна
			--------------------------------------------------------------*/
			resetPosition: () => {
				let x = (window.innerWidth - this.el.offsetWidth) / 2;
				let y = (window.innerHeight - this.el.offsetHeight) / 2;

				if (window.innerWidth > screen.width) {
					x = (screen.width - this.el.offsetWidth) / 2;
				}

				this.#lastPos = null;

				this.el.style.marginLeft = '';
				this.el.style.marginTop = '';
				this.el.style.left = x + 'px';
				this.el.style.top = y + 'px';
			},

			/*--------------------------------------------------------------
			Удаление события
			--------------------------------------------------------------*/
			end: () => {
				this.#drag.setSavedPositions();
				this.#dragOn = false;
				document.removeEventListener('mousemove', this.#drag.setPosition);
			},

			/*--------------------------------------------------------------
			Сохранение позиции окна в хранилище
			--------------------------------------------------------------*/
			setSavedPositions: () => {
				if (this.#saveDragPosition && this.#popupIdMd5 && this.#lastPos) {
					this.#savedPositions[this.#popupIdMd5] = this.#lastPos;

					localStorage.set(this.#storageKey, this.#savedPositions);
				}
			},

			/*--------------------------------------------------------------
			Получение позиции окна из хранилища
			--------------------------------------------------------------*/
			getSavedPositions: () => {
				if (this.#saveDragPosition) {
					this.#savedPositions = localStorage.get(this.#storageKey) || {};

					let currentPos = getVal(this.#savedPositions, this.#popupIdMd5, {});

					if (typeof currentPos === 'object' && Object.keys(currentPos).length) {
						this.#lastPos = {
							x: parseInt(currentPos.x, 10) || 0,
							y: parseInt(currentPos.y, 10) || 0,
						};
					}
				}
			},
		}
	})();
}