/*--------------------------------------------------------------
Импорт модулей
---------------------------------------------------------------*/
import AppStorage from "@common/AppStorage";
import md5 from 'md5';


class _ {
	#events = {};


	add = (event, handler, options = {}) => {
		if (typeof event === 'string' && typeof handler === 'function') {
			let optionsID = md5( JSON.stringify(options).replace(/ /g, '') );

			/*--------------------------------------------------------------
			Создание раздела для конкретного ивента
			--------------------------------------------------------------*/
			if (!this.#events[event]) {
				this.#events[event] = {};
			}

			/*--------------------------------------------------------------
			Создание раздела для каждого ивента с уникальными настройками
			--------------------------------------------------------------*/
			if (!this.#events[event][optionsID]) {
				this.#events[event][optionsID] = {
					handlers: [],
					options: options,
				};
			}

			/*--------------------------------------------------------------
			Создание обработчика с уникальным названием для ивентов
			с уникальными настройками и инициализация события
			--------------------------------------------------------------*/
			if (!this[`handler_${event}_${optionsID}`]) {
				this[`handler_${event}_${optionsID}`] = (e) => {
					this.#events[event][optionsID].handlers.forEach(fn => {
						if (typeof fn === 'function') {
							fn.call(window, e)
						}
					});
				}

				window.addEventListener(event, this[`handler_${event}_${optionsID}`], this.#events[event][optionsID].options);
			}

			/*--------------------------------------------------------------
			Добавление нового обработчика в массив
			--------------------------------------------------------------*/
			if (!this.#events[event][optionsID].handlers.some(entry => entry.handler === handler)) {
				this.#events[event][optionsID].handlers.push(handler);
			}


			// window.removeEventListener(event, this[`handler_${event}_${optionsID}`], this.#events[event][optionsID].options);
			// window.addEventListener(event, this[`handler_${event}_${optionsID}`], this.#events[event][optionsID].options);
		}
	};

	remove = (event, handler, options = {}) => {
		if (this.#events[event] && typeof handler === 'function') {
			let optionsID = md5( JSON.stringify(options).replace(/ /g, '') );

			if (this.#events[event][optionsID] && Array.isArray(this.#events[event][optionsID].handlers)) {
				this.#events[event][optionsID].handlers = this.#events[event][optionsID].handlers.filter(fn => fn !== handler);
			}
		}
	}
}


class WindowEvents {
	#storageName = `WindowEvents`;
	#instance = AppStorage.get(this.#storageName);

	constructor() {
		if (!this.#instance) {
			this.#instance = new _();

			AppStorage.set(this.#storageName, this.#instance);
		}

		this.#define();
	}

	#define = () => {
		this.add = this.#instance.add;
		this.remove = this.#instance.remove;
	};
}


export default new WindowEvents();