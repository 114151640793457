/*--------------------------------------------------------------
Импорт модулей
---------------------------------------------------------------*/
import {getVal} from "@common/object";


/**
 * Представляет класс, который обеспечивает операции хранения для пар ключ-значение.
 */
class _ {
	#storage = {};

	// Получение данных по пути
	get = (path) => getVal(this.#storage, path);

	// Установка данных по пути
	set = (path, value) => {
		let entry = this.#storage, // Начинаем с корневого узла хранилища
			keys = String(path).split('.');  // Разбиваем путь на части

		keys.forEach((key, i) => {
			if (i === keys.length - 1) {  // Если это последняя часть пути
				entry[key] = value;  // Устанавливаем значение
			}
			else {
				// Если следующий узел еще не определен, создаем его
				if (typeof entry[key] === 'undefined' && typeof entry[key] !== 'object') entry[key] = {};
			}

			// Переходим к следующему узлу
			entry = entry[key];
		});
	}

	// Удаление ключа
	remove = (key) => {
		if (typeof this.#storage[key] !== 'undefined') { // Если ключ существует
			delete this.#storage[key]; // Удаляем его
		}
	}
}


/**
 * Представляет хранилище данных текущего приложения
 *
 * @class
 */
class AppStorage {
	// Приватное поле, содержащее имя хранилища приложения
	#AppStorageName = `as${__CACHE.version}`;

	constructor() {
		// Если в глобальном объекте window нет нашего хранилища, то инициализируем его
		if (!window[this.#AppStorageName]) {
			window[this.#AppStorageName] = new _();
		}

		// Определяем методы get, set и remove
		this.#define();
	}

	// Приватный метод определения функционала хранилища
	#define = () => {
		// Метод получения данных из хранилища
		this.get = window[this.#AppStorageName].get;

		// Метод установки данных в хранилище
		this.set = window[this.#AppStorageName].set;

		// Метод удаления данных из хранилища
		this.remove = window[this.#AppStorageName].remove;
	};
}


export default new AppStorage();