export default class RatingStars {
	#rating = 0;

	constructor(el) {
		this.el = el;
		this.#rating = (this.el.getAttribute('data-rating') || this.#rating);

		this.#setRating();
	}

	#setRating = () => {
		let rating = this.#rating * 20;

		this.el.querySelectorAll('i').forEach(_el => {
			_el.style.width = `${ rating < 0 ? 0 : (rating > 100 ? 100 : rating) }%`;
		});

		this.el.removeAttribute('data-rating');
	}

	update = (rating) => {
		this.#rating = rating || this.el.getAttribute('data-rating') || this.#rating;
		this.#setRating();
	};
}