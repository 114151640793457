import * as DOM from "@common/dom";


export default class Select {
    constructor(el) {
        if (el && !el['__Select']) {
            this.select = el;
            this.title = this.select.querySelector('.s-title');
            this.titleText = this.title.querySelector('.text');
            this.options = this.select.querySelector('.s-options');
            this.hiddenInput = this.select.querySelector('input[type="hidden"]');
            this.search = this.title.querySelector('.s-search');

            if (this.search) {
                this.searchInput = this.search.querySelector('input');
            }

            this.onChange = null;

            this.#init();

            el['__Select'] = this;
        }
    }

    #init = () => {
        if (this.hiddenInput && this.hiddenInput.value) this.#valueFromHidden();

        this.title.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (!this.select.getAttribute('disabled')) this.#toggleOptions();
        });

        if (this.searchInput) {
            this.searchInput.addEventListener('keyup', this.#searching);
        }

        DOM.event(this.select, 'click', '.option', (e) => {
            this.#selectOption(e.currentTarget);
            DOM.trigger(this.hiddenInput, 'change');

            if (typeof this.onChange === 'function') {
                this.onChange.call(this, this);
            }
        });
    };

    /*--------------------------------------------------------------
	Set value from hidden input
	---------------------------------------------------------------*/
    #valueFromHidden = () => {
        let selected = this.options.querySelectorAll(`[data-value="${ this.hiddenInput.value }"]`);

        selected.forEach(el => {
            el.classList.add('selected')
        });

        this.#selectOption(selected[0]);
    };

    /*--------------------------------------------------------------
	Show/hide options
	---------------------------------------------------------------*/
    #toggleOptions = () => {
        let currentState = DOM.isVisible(this.options),
            firstSelected = DOM.find('.selected', this.options)[0];

        DOM.find('.ui-select.show').forEach(el => {
            el.classList.remove('show');
        });

        this.select.classList.toggle('show', !currentState);

        DOM.find('.option', this.options).forEach(el => {
            DOM.toggle(el, true);
        });

        /*--------------------------------------------------------------
		Scroll to selected option
		---------------------------------------------------------------*/
        if (firstSelected) {
            this.options.scrollTop = firstSelected.offsetTop || 0;
        }

        if (this.search) {
            DOM.toggle(this.search, true, 'flex');
            this.searchInput.value = this.titleText.innerText.trim();
            this.searchInput.focus();
            // this.title.classList.remove('changed');
            // this.titleText.innerHTML = '';
        }

        if (!currentState) {
            /*--------------------------------------------------------------
            Hide options when click anywhere outside of selection
            ---------------------------------------------------------------*/
            document.addEventListener('click', this.#hideAllOpenedSelects, {once: true});
        }
    };

    /*--------------------------------------------------------------
    Hide options hide All Opened Selects when click
    anywhere outside of select
    ---------------------------------------------------------------*/
    #hideAllOpenedSelects = (e) => {
        if (!DOM.is(e.currentTarget, '.ui-select *')) {
            DOM.find('.ui-select.show').forEach(el => {
                el.classList.remove('show');
            });
        }
    };

    /*--------------------------------------------------------------

    ---------------------------------------------------------------*/
    #searching = () => {
        let contains = DOM.findContains(this.options, '.option', this.searchInput.value, (el, isContains) => {
            DOM.toggle(el, isContains);
        });

        this.select.classList.toggle('show', contains.length);
    };

    /*--------------------------------------------------------------

    ---------------------------------------------------------------*/
    #selectOption = (option) => {
        if (option) {
            let clone = option.cloneNode(true);

            clone.querySelectorAll('.ignored').forEach(el => {
                el.remove()
            });

            this.select.querySelectorAll('.option.selected').forEach(el => {
                el.classList.remove('selected');
            });

            if (this.search) {
                DOM.toggle(this.search, false);
                this.titleText.innerHTML = clone.innerText.trim();
            }
            else {
                this.title.innerHTML = clone.innerHTML;
            }

            this.title.classList.add('changed');

            this.hiddenInput.value = option.getAttribute('data-value');

            this.select.classList.remove('show');

            option.classList.add('selected');
        }
    };
}