import { isEmpty } from '@common/common';
import * as DOM from "@common/dom";
import AppStorage from "@common/AppStorage";


/*--------------------------------------------------------------
Set templates to main constant
---------------------------------------------------------------*/
export function setTemplatesList(){
    document.querySelectorAll('template').forEach((el) => {
        AppStorage.set(`templates.${ el.getAttribute('data-template') }`, el.innerHTML);
    });

    document.querySelectorAll('.templates-container').forEach((el) => {
        el.parentElement.removeChild(el);
    });
}

/*--------------------------------------------------------------
Установка шаблона
---------------------------------------------------------------*/
export function set(variables, targets, templateName, insertMethod) {
    /*--------------------------------------------------------------
    Преобразование любого типа "targets" в массив элементов
    (string selector, node element, nodeList, jquery object)
    ---------------------------------------------------------------*/
    targets = DOM.getElementsArray(targets);

    if (!targets || !targets.length) {
        // if (jsConfig['debugMode']) console.error(`Template: ${templateName} - need to set "targets"!`)
        return false;
    }

    variables = Array.isArray(variables) ? variables : (!!variables && typeof variables === 'object' && Object.keys(variables).length ? [variables] : [{}]);

    if (Array.isArray(variables)) {
        let _arr = [];

        variables.forEach((_v, i) => {
            _arr.push(replaceVariables(_v, templateName, i));
        });

        /*--------------------------------------------------------------
		Создание временного контейнера для обработки и вставки
		---------------------------------------------------------------*/
        let template = document.createElement('template');
        template.innerHTML = _arr.join('');

        let container = document.createElement('div');
        container.appendChild(template.content);

        /*--------------------------------------------------------------
        Очистка HTML
        --------------------------------------------------------------*/
        clearHTML(container);

        /*--------------------------------------------------------------
        Вставка в DOM
        --------------------------------------------------------------*/
        targets.forEach(target => {
            /*--------------------------------------------------------------
            родительский элемент в котором будет проводиться
            поиск [data-x-setval] элементов
            --------------------------------------------------------------*/
            let targetParent;

            switch (insertMethod) {
                case 'append': {
                    targetParent = target;
                    target.insertAdjacentHTML('beforeend', container.innerHTML);

                    break;
                }
                case 'prepend': {
                    targetParent = target;
                    target.insertAdjacentHTML('afterbegin', container.innerHTML);

                    break;
                }
                case 'after': {
                    targetParent = target.parentNode;
                    target.insertAdjacentHTML('afterend', container.innerHTML);

                    break;
                }
                case 'before': {
                    targetParent = target.parentNode;
                    target.insertAdjacentHTML('beforebegin', container.innerHTML);

                    break;
                }
                case 'instead': {
                    targetParent = target.parentNode;
                    target.insertAdjacentHTML('afterend', container.innerHTML);
                    target.remove();

                    break;
                }
                case 'replace':
                default: {
                    targetParent = target;
                    target.innerHTML = '';
                    target.insertAdjacentHTML('beforeend', container.innerHTML);

                    break;
                }
            }

            setValuesIntoElement(variables, targetParent);
        });
    }
    else {
        if (jsConfig['debugMode']) console.error('Template.set: "variables" must be an array!')
    }
}

/*--------------------------------------------------------------
Подстановка значений в шаблон
--------------------------------------------------------------*/
export function replaceVariables(variables = {}, templateName, index) {
    const template = AppStorage.get(`templates.${templateName}`) || '';

    if (template) {
        const regexEmptyClass = /(class="\s*")/g;
        const regexVariable = /\${([^{}]*)}/g;
        const regexSetval = new RegExp('data-x-setval="([^"]*)"', 'g');
        // const regexEvt = new RegExp('data-x-evt="([^"]*)"', 'g');

        if (jsConfig['debugMode']) {
            for (let key in variables) {
                if (variables.hasOwnProperty(key) && !key.includes('_') && !template.includes('${' + key + '}')) {
                    console.info('Variable %c ' + key + ' %c not found in %c ' + templateName + ' %c template.', 'color:#fdffbd;background:#000;font-weight:700;', '', 'color:#fdffbd;background:#000;font-weight:700;', '');
                }
            }
        }

        return template
            .replace(regexSetval, `data-x-setval="${index}::$1"`)
            // .replace(regexEvt, `data-x-evt="${index}::$1"`)
            .replace(regexVariable, (_, key) => {
                if (variables.hasOwnProperty(key)) {
                    if (isEmpty(variables[key])) return '';

                    return variables[key];
                }

                return '${' + key + '}'
            })
            .replace(regexEmptyClass, '')
    }
    else {
        if (jsConfig['debugMode']) console.error('Template with name: %c' + templateName + '%c not found!', 'color:#fdffbd;background:#000;font-weight:700;', '');

        return '';
    }
}

/*--------------------------------------------------------------
Очистка HTML от временных атрибутов
--------------------------------------------------------------*/
function clearHTML(target){
    /*--------------------------------------------------------------
    Удаляем (не отображаем в шаблоне) то, что определено атрибутом data-x-toggle
    ---------------------------------------------------------------*/
    target.querySelectorAll('[data-x-toggle]').forEach((el) => {
        let val = el.getAttribute('data-x-toggle');

        el.removeAttribute('data-x-toggle');

        if (!val || ['false', 'undefined', 'null', '0', ''].includes(val)) {
            el.remove();
        }
    });

    /*--------------------------------------------------------------
	Отмечаем чекбоксы/радиобоксы относительно атрибута data-x-checked
	---------------------------------------------------------------*/
    target.querySelectorAll('[type=checkbox][data-x-checked], [type=radio][data-x-checked]').forEach((el) => {
        let val = el.getAttribute('data-x-checked');

        val = !val || ['false', 'undefined', 'null', '0', ''].includes(val);

        el.checked = !val;

        if (!val) {
            el.setAttribute('checked', '');
        }
        else {
            el.removeAttribute('checked');
        }

        el.removeAttribute('data-x-checked');
    });

    /*--------------------------------------------------------------
	Отмечаем option в селекте относительно атрибута data-x-selected
	---------------------------------------------------------------*/
    target.querySelectorAll('option[data-x-selected]').forEach((el) => {
        let val = el.getAttribute('data-x-selected');

        val = !val || ['false', 'undefined', 'null', '0', ''].includes(val);

        el.checked = !val;

        if (!val) {
            el.setAttribute('selected', '');
        }
        else {
            el.removeAttribute('selected');
        }

        el.removeAttribute('data-x-selected');
    });

    /*--------------------------------------------------------------
	Поля только для чтения согласно атрибуту data-x-disabled
	---------------------------------------------------------------*/
    target.querySelectorAll('[data-x-disabled]').forEach((el) => {
        let val = el.getAttribute('data-x-disabled');

        val = !val || ['false', 'undefined', 'null', '0', ''].includes(val);

        el.disabled = !val;

        if (!val) {
            el.setAttribute('disabled', '');
        }
        else {
            el.removeAttribute('disabled');
        }
        // el.toggleAttribute('disabled', !val);

        el.removeAttribute('data-x-disabled');
    });

    /*--------------------------------------------------------------
	Обязательные поля только согласно атрибуту data-x-required
	---------------------------------------------------------------*/
    target.querySelectorAll('[data-x-required]').forEach((el) => {
        let val = el.getAttribute('data-x-required');

        val = !val || ['false', 'undefined', 'null', '0', ''].includes(val);

        el.required = !val;

        if (!val) {
            el.setAttribute('required', '');
        }
        else {
            el.removeAttribute('required');
        }
        // el.toggleAttribute('required', !val);

        el.removeAttribute('data-x-required');
    });

    /*--------------------------------------------------------------
	Подстановка ссылок
	---------------------------------------------------------------*/
    target.querySelectorAll('[data-href]').forEach(el => {
        el.href = el.getAttribute('data-href');
        el.removeAttribute('data-href');
    });

    /*--------------------------------------------------------------
	Загрузка изображений
	---------------------------------------------------------------*/
    target.querySelectorAll('img[data-src]').forEach(img => {
        img.src = img.getAttribute('data-src');
        img.removeAttribute('data-src');
    });
}

/*--------------------------------------------------------------
ВНИМАНИЕ: не работает в replaceVariables снаружи!
Подстановка значений в элементы
--------------------------------------------------------------*/
function setValuesIntoElement(variables, target){
    target.querySelectorAll('[data-x-setval]').forEach(el => {
        let attrValue = String(el.getAttribute('data-x-setval')).split('::');
        let templateIndex = attrValue[0];
        let keysList = attrValue[1] ? attrValue[1].split(',').map(k => k.trim()) : [];

        keysList.forEach(key => {
            let value = variables?.[templateIndex]?.[key];

            if (typeof value !== 'undefined') {
                el[`${key}`] = value;
            }
        });

        el.removeAttribute('data-x-setval');
    });

    // target.querySelectorAll('[data-x-evt]').forEach(el => {
    //     let attrValue = String(el.getAttribute('data-x-evt')).split('::');
    //     let templateIndex = attrValue[0];
    //     let keysList = attrValue[1] ? attrValue[1].split(',').map(k => k.trim()) : [];
    //
    //     keysList.forEach(key => {
    //         let value = variables?.[templateIndex]?.[key];
    //
    //         if (typeof value === 'object' && Object.keys(value).length) {
    //             let events = typeof value.event === 'string' ? value.event.split(' ') : [];
    //             let handler = typeof value.handler === 'function' ? value.handler : null;
    //
    //             if (events.length && handler) {
    //                 events.forEach(event => {
    //                     event = event.trim();
    //
    //                     if (!!event) {
    //                         el.addEventListener(event, handler);
    //                     }
    //                 });
    //             }
    //         }
    //     });
    //
    //     el.removeAttribute('data-x-evt');
    // });
}