/*--------------------------------------------------------------
Загрузка ресурса по url
--------------------------------------------------------------*/
export function loadScript(url, isAsync = true, type = 'script') {
	return new Promise((resolve, reject) => {
		try {
			const tag = type === 'style' ? 'link' : 'script';

			// Проверяем, загружен ли уже скрипт с таким src
			const existingScript = document.querySelector(tag === 'script' ? `script[src="${url}"]` : `link[href="${url}"]`);

			// Скрипт уже загружен
			if (existingScript) {
				resolve();

				return;
			}

			const item = document.createElement(tag);

			if (type === 'style') {
				item.rel = 'stylesheet';
				item.href = url;
				item.async = isAsync;
			}
			else {
				item.src = url;
				item.type = 'text/javascript';
				item.async = isAsync;
			}

			item.onload = () => {
				resolve();
			};

			item.onerror = () => {
				reject(`Failed to load "${type}" with src: ${url}`);
			};

			document.head.appendChild(item);
		}
		catch (error) {
			reject(error);
		}
	});
}

/*--------------------------------------------------------------
Прогрузка списка ресурсов
--------------------------------------------------------------*/
export function loadAll(items, isAsync = true, type = 'script') {
	const promises = items.map(item => loadScript(item, isAsync, type));
	return Promise.all(promises);
}