import md5 from 'md5';
import AppStorage from "@common/AppStorage";


export function generateVendorLogo(vendorName = '', imageSize = 512, fontFamily = 'Roboto, sans-serif', fontWeight = 'bold'){
	vendorName = decodeHtmlEntities(vendorName);

	let vendorID =  md5(vendorName + '.' + imageSize);
	let savedLogo = AppStorage.get(`savedVendorLogos.${vendorID}`);

	if (savedLogo) return savedLogo;

	let canvas = document.createElement('canvas');
	let logoSize = imageSize;
	let padding = (logoSize - logoSize * .8) / 2;
	let innerSize = logoSize - 2 * padding;

	canvas.width = logoSize;
	canvas.height = logoSize;

	let context = canvas.getContext('2d');
	// context.fillStyle = '#eee'; // Set the background color
	// context.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with the background color
	context.fillStyle = generateColor(vendorName);
	// context.fillStyle = '#000';
	context.textAlign = 'center';

	let words = [];

	vendorName.split(' ').forEach(w => {
		if (w.length > 2 || !words.length) {
			words.push(w);
		}
		else {
			words[words.length - 1] = words[words.length - 1] + ' ' + w;
		}
	});

	if (words.length > 1) {
		words = prepareRows([].concat(words));
	}

	let y = padding + innerSize / 2;
	let fontSize = 16;
	let maxLineWidth = 0;

	for (let word of words) {
		context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
		let wordWidth = context.measureText(word).width;

		if (wordWidth > innerSize) {
			let scaledFontSize = Math.floor(innerSize / wordWidth * fontSize);
			context.font = `${fontWeight} ${scaledFontSize}px ${fontFamily}`;
			wordWidth = context.measureText(word).width;

			if (wordWidth > maxLineWidth) {
				maxLineWidth = wordWidth;
			}
		} else if (wordWidth > maxLineWidth) {
			maxLineWidth = wordWidth;
		}
	}

	let scaleRatio = innerSize / maxLineWidth;
	let scaledFontSize = Math.floor(scaleRatio * fontSize);
	let lineHeight = scaledFontSize + 4; // Изменить значение, если нужно больше пространства между строками
	let totalHeight = words.length * lineHeight;

	let offsetY = y - totalHeight / 2 + scaledFontSize / 1.2;

	for (let word of words) {
		context.font = `${fontWeight} ${scaledFontSize}px ${fontFamily}`;
		context.fillText(word, logoSize / 2, offsetY);
		offsetY += lineHeight;
	}

	let _img = canvas.toDataURL();

	AppStorage.set(`savedVendorLogos.${vendorID}`, _img)

	return _img;
}

function prepareRows(rows){
	for (let i = 1; i < rows.length; i++) {
		if (rows[i - 1].length + rows[i].length <= 10) {
			rows[i] = rows[i - 1] + ' ' + rows[i];
			rows[i - 1] = null;

			return prepareRows(rows.filter(r => r))
		}
	}

	return rows.filter(r => r)
}

function decodeHtmlEntities(text) {
	let div = document.createElement('div');
	div.innerHTML = text;
	return div.innerText
}

function generateColor(text) {
	// Преобразуем текст в хеш-код
	let hashCode = 0;
	for (let i = 0; i < text.length; i++) {
		hashCode = text.charCodeAt(i) + ((hashCode << 5) - hashCode);
	}

	// Генерируем цвет на основе хеш-кода
	const hue = Math.abs(hashCode) % 360; // Оттенок (0-359)
	const saturation = Math.abs(hashCode % 25) + 70; // Насыщенность (70-94)
	const lightness = Math.abs(hashCode % 15) + 20; // Светлота (20-34)

	// Преобразуем HSL в RGB
	const hslToRgb = (h, s, l) => {
		const c = (1 - Math.abs(2 * l - 1)) * s;
		const x = c * (1 - Math.abs((h / 60) % 2 - 1));
		const m = l - c / 2;
		let r, g, b;

		if (h >= 0 && h < 60) {
			r = c;
			g = x;
			b = 0;
		} else if (h >= 60 && h < 120) {
			r = x;
			g = c;
			b = 0;
		} else if (h >= 120 && h < 180) {
			r = 0;
			g = c;
			b = x;
		} else if (h >= 180 && h < 240) {
			r = 0;
			g = x;
			b = c;
		} else if (h >= 240 && h < 300) {
			r = x;
			g = 0;
			b = c;
		} else {
			r = c;
			g = 0;
			b = x;
		}

		return [
			Math.round((r + m) * 255),
			Math.round((g + m) * 255),
			Math.round((b + m) * 255)
		];
	};

	// Преобразуем RGB в шестнадцатеричный формат
	const rgbToHex = (r, g, b) => {
		const componentToHex = (c) => {
			const hex = c.toString(16);
			return hex.length === 1 ? "0" + hex : hex;
		};

		return (
			"#" +
			componentToHex(r) +
			componentToHex(g) +
			componentToHex(b)
		);
	};

	const [red, green, blue] = hslToRgb(hue, saturation / 100, lightness / 100);
	return rgbToHex(red, green, blue)
}