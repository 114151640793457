import {getVal, nestedExist} from '@common/object';
import * as API from "@common/api";
import md5 from 'md5';
import Popup from "@components/Popup";
import {loadScript} from "@common/loadScript";


export default class GoogleRecaptcha {
	#googleRecaptchaUrl = 'https://www.google.com/recaptcha/api.js';
	#keyV2 = getVal(jsConfig, 'googleApi.recaptcha.keyV2', 'UNDEFINED_KEY_V2');
	#keyV3 = getVal(jsConfig, 'googleApi.recaptcha.keyV3', 'UNDEFINED_KEY_V3');
	#verifyRecaptchaUrl = API.url('do-verify-recaptcha');
	#modalID = 'gt-recaptcha';
	#placeholderClass = 'gt-recaptcha-placeholder';


	constructor(popupSettings) {
		popupSettings = popupSettings || {
			backdrop: 'disabled',
			fullSize: 'disabled',
			keyboard: 'disabled',
			subWindow: true,
		};

		if (!document.querySelector(`#${this.#modalID}`)) {
			this.#setContainer();
		}

		this.recaptchaPopup = new Popup(`#${this.#modalID}`, popupSettings);

		this.placeholder = this.recaptchaPopup.el.querySelector(`.${this.#placeholderClass}`);
	}

	#setContainer = () => {
		let popup = document.createElement('div'),
			placeholder = document.createElement('div');

		popup.id = this.#modalID;
		popup.className = 'popup-window';

		placeholder.className = this.#placeholderClass;

		popup.append(placeholder);
		document.body.append(popup);
	};

	v3 = () => {
		return new Promise((resolve, reject) => {
			loadScript(`${this.#googleRecaptchaUrl}?render=${this.#keyV3}&hl=${getVal(jsConfig, 'url.language', 'uk')}`)
			.then(() => {
				if (grecaptcha) {
					grecaptcha.ready(() => {
						grecaptcha.execute(this.#keyV3, {action: 'default'}).then(token => {
							resolve(
								API.get(this.#verifyRecaptchaUrl, {
									data: {
										'token': token,
										'version': 3
									}
								})
								.then(this.#checkIsBot)
							)
						});
					});
				}
				else {
					reject();
				}
			})
			.catch(() => {
				reject()
			})
		});
	};

	v2 = token => {
		return new Promise((resolve, reject) => {
			resolve(
				API.get(this.#verifyRecaptchaUrl, {
					data: {
						'token': token,
						'version': 2
					}
				})
				.then(this.#checkIsBot)
			)
		});
	};

	#checkIsBot = data => {
	    return new Promise((resolve, reject) => {
			if (nestedExist(data, 'd.bot')) {
				/*--------------------------------------------------------------
				Always create new ID for captcha rendering element
				---------------------------------------------------------------*/
				let id = md5(String(Math.random()) + Date.now());

				this.placeholder.innerHTML = `<div id="${id}"></div>`;

				this.recaptchaPopup.show();

				grecaptcha.render(id, {
					'sitekey': this.#keyV2,
					'callback': token => {
						resolve(this.v2(token))
					},
				});
			}
			else {
				this.recaptchaPopup.hide();

				resolve(getVal(data, 'd'));
			}
	    });
	};

}
