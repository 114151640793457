/*--------------------------------------------------------------
Check if the value is a number
---------------------------------------------------------------*/
export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/*--------------------------------------------------------------
Empty values: undefined, null, false, {}, []
---------------------------------------------------------------*/
export function isEmpty(x) {
    return [undefined, null, false].includes(x) || (typeof x === 'object' && !Object.keys(x).length) || (Array.isArray(x) && !x.length);
}