import * as DOM from "@common/dom";


/*--------------------------------------------------------------
Слежение за изменением атрибутов выбранных элементов
---------------------------------------------------------------*/
export function attributes(targets, attributesArray, callback) {
    targets = DOM.getElementsArray(targets);

    if (targets.length) {
        let config = {
            attributes: true,
            childList: false,
            subtree: false
        };

        let observer = new MutationObserver(function (mutationsList, observer) {
            for (let mutation of mutationsList) {
                let results = {};

                attributesArray.forEach(attr => {
                    if (mutation.attributeName === attr && mutation.target.attributes[attr]) {
                        results[attr] = mutation.target.attributes[attr].nodeValue;
                    }
                });

                if (Object.keys(results).length && typeof callback === 'function') {
                    callback.apply(mutation.target, [results, this]);
                }
            }
        });

        targets.forEach(el => {
            observer.observe(el, config);
        });

        return observer;
    }
}


/**
 * @description Слежение за изменением атрибутов выбранных элементов
 * @param targets - родительские элементы за которыми необходимо наблюдать
 * @param itemsArray - массив селекторов целевых элементов
 * @param callback - обработчик
 * @return observer object
 */
export function childList(targets, itemsArray, callback) {
    targets = DOM.getElementsArray(targets);

    if (targets.length) {
        let config = {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
        };
        
        let observer = new MutationObserver(function (mutationsList, observer) {
            for (let mutation of mutationsList) {
                let results = {};

                if (mutation.type === 'childList' && mutation.addedNodes.length) {
                    mutation.addedNodes.forEach(node => {
                        itemsArray.forEach(itemSelector => {
                            let _arr = [],
                                children = DOM.find(itemSelector, node);

                            if (DOM.is(node, itemSelector) && _arr.every(_node => _node !== node)) {
                                _arr.push(node);
                            }

                            if (children.length) {
                                children.forEach(el => {
                                    if (_arr.every(_node => _node !== el)) {
                                        _arr.push(el);
                                    }
                                });
                            }

                            if (_arr.length) {
                                if (!results.hasOwnProperty(itemSelector)) {
                                    results[itemSelector] = _arr;
                                }
                                else {
                                    results[itemSelector] = results[itemSelector].concat(_arr);
                                }
                            }
                        })
                    })
                }

                if (Object.keys(results).length && typeof callback === 'function') {
                    callback.apply(mutation.target, [results, this]);
                }
            }
        });

        targets.forEach(el => {
            observer.observe(el, config);
        });

        return observer;
    }
}

/*--------------------------------------------------------------
Слежение за изменением размера выбранных элементов
---------------------------------------------------------------*/
export function resize(targets, callback){
    if (typeof ResizeObserver === 'undefined') {
        console.info('Your browser does not support ResizeObserver');
    }
    else {
        targets = DOM.getElementsArray(targets);

        let observer = new ResizeObserver(function (entries, observer) {
            for (let entry of entries) {
                let size = {},
                    contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize,
                    borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

                if (contentBoxSize) {
                    size['width'] = Math.floor(borderBoxSize.inlineSize);
                    size['height'] = Math.floor(borderBoxSize.blockSize);
                    size['contentWidth'] = Math.floor(contentBoxSize.inlineSize);
                    size['contentHeight'] = Math.floor(contentBoxSize.blockSize);
                }
                else if (entry.contentRect) {
                    size['width'] = entry.contentRect.width;
                    size['height'] = entry.contentRect.height;
                }

                if (typeof callback === 'function') {
                    callback.apply(entry.target, [size, this]);
                }
            }
        });

        targets.forEach(el => {
            observer.observe(el);
        });

        return observer;
    }
}

/*--------------------------------------------------------------
Слежение за изменением пересечения элемента с его родителем
или областью видимости документа viewport
---------------------------------------------------------------*/
export function intersection(targets, parent, callback){
    targets = DOM.getElementsArray(targets);

    parent = parent || document;

    let options = {
        root: parent,
        // rootMargin: '0px',
        // threshold: 1.0
    };

    let observer = new IntersectionObserver(function (entries, observer) {
        if (entries) {
            if (typeof callback === 'function') {
                callback.call(this, entries, observer);
            }
        }
    });

    targets.forEach((el) => {
        observer.observe(el, options);
    });

    return observer;
}