/*--------------------------------------------------------------
Remove element from array
---------------------------------------------------------------*/
export function removeItem(arr, element) {
    return arr.filter(function (e) {
        return e !== element;
    });
}

/*--------------------------------------------------------------
Remove element from array by index
---------------------------------------------------------------*/
export function removeItemByIndex(arr, index) {
    return arr.filter(function (e, i) {
        return i !== index;
    });
}

/*--------------------------------------------------------------
Check if an array element exists
---------------------------------------------------------------*/
export function itemExists(arr, element) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === element) result.push(i);
    }
    return result.length ? result : false;
}

/*--------------------------------------------------------------
Shuffle array
---------------------------------------------------------------*/
export function shuffle(array) {
    array = Array.isArray(array) ? array : [];

    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array
}