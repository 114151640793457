/*--------------------------------------------------------------
Импорт модулей
---------------------------------------------------------------*/
import * as DOM from "@common/dom";
import * as API from "@common/api";
import {getVal} from "@common/object";
import AppStorage from "@common/AppStorage";



export default class ProductItem {
	#nameSpaces = {
		wishlistBtn: 'button.wishlist',
		compareBtn: 'button.compare',
		addBtn: 'button.add',
		activeButtonsClass: 'selected',
	};
	#productID;


	constructor(el, settings) {
		if (el && !el.__ProductItem) {
			settings = settings || {};

			this.urls = {
				doWishlistAction: API.url('do-wishlist-action'),
			};

			this.el = el;

			this.#productID = this.el.getAttribute('data-product-id');

			if (settings.onAdd) {
				this.onAdd = settings.onAdd;
			}

			el.__ProductItem = this;

			this.#init();
		}
	}

	#init = () => {
		/*--------------------------------------------------------------
		Удаление прелоадера после загрузки изображения
		---------------------------------------------------------------*/
		this.el.querySelectorAll('.pr-image._loading img').forEach(img => {
			img.addEventListener('load', () => {
				img.closest('.pr-image').classList.remove('_loading');

				if (img.complete) {
					DOM.trigger(img, 'load')
				}
			}, {once: true});
		});

		/*--------------------------------------------------------------
		Подгрузка второй картинки товара при наведении мыши
		---------------------------------------------------------------*/
		this.el.querySelectorAll('[data-second-img]').forEach(prImage => {
			prImage.addEventListener('mouseover', () => {
				let src = prImage.getAttribute('data-second-img'),
					imgs = prImage.querySelectorAll('img');

				if (src && imgs.length < 2) {
					let image = new Image();

					image.src = src;
					image.alt = imgs[0] ? imgs[0].getAttribute('alt') : '';

					image.onload = () => {
						prImage.append(image);
					};
				}

				prImage.removeAttribute('data-second-img');
			}, {once: true});
		});

		/*--------------------------------------------------------------
		
		---------------------------------------------------------------*/
		this.el.querySelectorAll(this.#nameSpaces.wishlistBtn).forEach(btn => {
		    btn.addEventListener('click', () => {
				if (!AppStorage.get('UserID')) {
					AppStorage.get('AuthPopup').show();
				}
				else {
					if (DOM.hasClass(btn, this.#nameSpaces.activeButtonsClass)) {
						this.wishlistDelete(() => {
							btn.classList.toggle(this.#nameSpaces.activeButtonsClass);
						});
					}
					else {
						this.wishlistAdd(() => {
							btn.classList.toggle(this.#nameSpaces.activeButtonsClass);
						});
					}
				}
		    });
		});
		
		/*--------------------------------------------------------------
		
		---------------------------------------------------------------*/
		this.el.querySelectorAll(this.#nameSpaces.compareBtn).forEach(btn => {
			btn.addEventListener('click', () => {
				if (!AppStorage.get('UserID')) {
					AppStorage.get('AuthPopup').show();
				}
				else {
					console.log('COMPARE');
				}
			});
		});

		/*--------------------------------------------------------------

		---------------------------------------------------------------*/
		this.el.querySelectorAll(this.#nameSpaces.addBtn).forEach(btn => {
			btn.addEventListener('click', () => {
				if (typeof this.onAdd === 'function') {
					this.onAdd.call(this);
				}
			});
		});
	};

	/*--------------------------------------------------------------
	Метод добавления товара в список желаний
	---------------------------------------------------------------*/
	wishlistAdd = (callback) => {
		API.get(this.urls.doWishlistAction, {
			preloader: false,
			data: {
				action: 'add',
				product_id: this.#productID,
			}
		})
		.then(data => {
			if (data) {
				this.#updateWishlistCount(getVal(data, 'd.count', 0));

				if (typeof callback === 'function') {
					callback.call(this)
				}
			}
		});
	};

	/*--------------------------------------------------------------
	Метод удаления товара из списка желаний
	---------------------------------------------------------------*/
	wishlistDelete = (callback) => {
		API.get(this.urls.doWishlistAction, {
			preloader: false,
		    data: {
				action: 'delete',
				product_id: this.#productID,
			}
		})
		.then(data => {
			if (data) {
				this.#updateWishlistCount(getVal(data, 'd.count', 0));

				if (typeof callback === 'function') {
					callback.call(this)
				}
			}
		});
	};

	/*--------------------------------------------------------------
	Обновить кол-во "желаний" (header/sidebar)
	---------------------------------------------------------------*/
	#updateWishlistCount = (offers_count) => {
		let toggler = !!parseInt(offers_count, 10);

		document.querySelectorAll('header .wishlist').forEach((el) => {
			el.querySelectorAll('a').forEach((a) => {
				a.classList.toggle('disabled', !toggler)
			});

			el.querySelectorAll('.notification').forEach((el) => {
				el.classList.toggle('show', toggler);
				el.innerText = offers_count;
			});
		});

		document.querySelectorAll('[data-id="sl-wishlist"]').forEach((el) => {
			el.querySelectorAll('.count').forEach((el) => {
				el.classList.toggle('show', toggler);
				el.innerText = offers_count;
			});
		});
	};
}