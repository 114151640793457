import {getVal} from '@common/object';
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import * as API from "@common/api";
import AppStorage from "@common/AppStorage";
import {loadAll} from "@common/loadScript";


/*--------------------------------------------------------------
Список ID всех проработанных на странице шаблонов
---------------------------------------------------------------*/
const __completedTemplates = [];

const BUILD_UPDATED = parseInt(__DEV ? Math.floor(Date.now() / 1000) : __TIMESTAMP, 10);
const CACHE_NAME = __CACHE.name.default + (__DEV ? 'DEV-' + Math.floor(Date.now() / 1000) : __CACHE.version);

const language = getVal(jsConfig, 'url.language', 'uk');
const remoteTemplatesKey = 'remote-templates';


/*--------------------------------------------------------------
Получение шаблона из AppStorage
--------------------------------------------------------------*/
export async function get(templatePath) {
	if (!templatePath) {
		return null;
	}

	let key = md5(templatePath),
		remoteTemplates = AppStorage.get(remoteTemplatesKey);

	if (!remoteTemplates) {
		try {
			// Подготовка шаблонов
			await prepareRemoteTemplates();

			remoteTemplates = AppStorage.get(remoteTemplatesKey);
		}
		catch (error) {
			// Возвращаем null, если при подготовке шаблонов произошла ошибка
			// console.error("Error preparing remote templates", error);

			return null;
		}
	}

	//Если все прошло хорошо, возвращаем шаблон
	return getVal(remoteTemplates, `${key}.${language}`, null);
}


/*--------------------------------------------------------------
Ищем в кеше сохраненные шаблоны remote-templates и помещаем их
в AppStorage → remoteTemplatesKey для быстрого доступа
из любого места
--------------------------------------------------------------*/
async function prepareRemoteTemplates() {
	if (!('caches' in self)) {
		throw new Error('Cache API not available');
	}

	const cache = await caches.open(CACHE_NAME);

	const response = await cache.match(remoteTemplatesKey);

	if (!response) {
		throw new Error('No template in cache');
	}

	const data = await response.text();

	try {
		const parsedData = JSON.parse(data);

		AppStorage.set(remoteTemplatesKey, parsedData);

		return true;
	}
	catch (error) {
		throw new Error('Invalid JSON in cache');
	}
}


/*--------------------------------------------------------------
Проверка наличия и валидность шаблона
вернет true если все хорошо
---------------------------------------------------------------*/
export async function check(templatePath) {
	// console.log('Проверка шаблона:', templatePath);
	if (!templatePath) {
		if (jsConfig['debugMode']) {
			console.error('templatesCache.check:\nNeed to set templatePath!');
		}
		return false;
	}

	try {
		const _template = await get(templatePath);
		return _template ? BUILD_UPDATED === parseInt(_template['updated'], 10) : false;
	}
	catch (error) {
		return false;
	}
}


/*--------------------------------------------------------------
Запись шаблонов в AppStorage и вставка в DOM
---------------------------------------------------------------*/
export function set(templatePath, targetSelector) {
	return new Promise(async (resolve, reject) => {
		// console.log('Запись в AppStorage', templatePath);

		if (!templatePath) {
			return resolve();
		}

		let key = md5(templatePath);

		if (__completedTemplates.includes(key)) {
			return resolve();
		}


		try {
			const _template = await get(templatePath);
			const storageTemplates = AppStorage.get('templates') || {};

			if (typeof _template['templates'] === 'object') {
				Object.keys(_template['templates']).forEach(function (key){
					storageTemplates[key] = _template['templates'][key];
				}, _template['templates']);

				AppStorage.set('templates', storageTemplates);
			}

			if (Array.isArray(_template['styles']) && _template['styles'].length) {
				try {
					await loadAll(_template['styles'], true, 'style');
				}
				catch (error) {
					console.error(error);
				}
			}

			if (_template['html']) {
				const targets = targetSelector ? document.querySelectorAll(targetSelector) : [document.body];

				targets.forEach((target) => {
					target.insertAdjacentHTML('beforeend', _template['html']);
				});
			}

			__completedTemplates.push(key);

			resolve();
		}
		catch (error) {
			reject(error);
		}
	});
}


/*--------------------------------------------------------------
1. проверит наличие и валидность шаблона
2. запросит шаблон по названию с сервера (если шаблон отсутствует)
3. сохранит шаблон
4. выполнит колбэк
---------------------------------------------------------------*/
export async function CGS(templatePath, section) {
	if (!templatePath) {
		throw new Error('CGS: Need to set templatePath');
	}

	const state = await check(templatePath);

	if (!state) {
		return requestTemplate([templatePath], section);
	}

	return set(templatePath);
}


/*--------------------------------------------------------------
Запрос шаблонов с сервера
---------------------------------------------------------------*/
export function requestTemplate(templatesArray, section) {
	if (!Array.isArray(templatesArray) || !templatesArray.length) {
		return Promise.reject(new Error("requestTemplate requires a non-empty array of templates."));
	}

	return API.get(`${ API.url('get-templates', section) }?uid=${uuidv4()}`, {
		preloader: false,
		data: {_t: templatesArray}
	});
}


/*--------------------------------------------------------------
Запись шаблонов в AppStorage
используется только в колбеке запроса (api.js)
---------------------------------------------------------------*/
export async function saveRemoteTemplates(templatesArray) {
	// Получение сохраненных шаблонов из локального хранилища или инициализация пустого объекта, если ничего нет
	const remoteTemplates = AppStorage.get(remoteTemplatesKey) || {};

	// Проверка наличия шаблонов для сохранения
	if (!Array.isArray(templatesArray) || !templatesArray.length) {
		return;
	}

	// Обработка каждого шаблона в переданном массиве
	for (const template of templatesArray) {
		const { name, data } = template;

		// Пропускаем текущий шаблон, если у него отсутствует имя или данные
		if (!name || !data) {
			continue;
		}

		const key = md5(name);

		// Если шаблон уже был обработан и существует в сохраненных шаблонах, то пропускаем его
		if (__completedTemplates.includes(key) && getVal(remoteTemplates, `${key}.${language}`, null)) {
			continue;
		}

		// Создание пустого объекта для шаблона, если этого еще не было сделано
		if (!remoteTemplates.hasOwnProperty(key)) {
			remoteTemplates[key] = {};
		}

		// Запись информации о шаблоне в объект шаблонов
		// Время обновления устанавливается текущим временем или временем сборки
		remoteTemplates[key][language] = {
			updated: __DEV ? Math.floor(Date.now() / 1000) : __TIMESTAMP,
			templates: {}
		};

		// Создаем DOM элемент из данных шаблона
		const div = document.createElement('div');
		div.innerHTML = data;

		// Находим все теги template в созданном элементе
		const templateEls = div.querySelectorAll('.templates-container > template');

		// Обходим все найденные теги template
		for (const _t of templateEls) {
			// Извлекаем атрибут data-template и тело шаблона
			const tName = _t.getAttribute('data-template');
			const tBody = _t.innerHTML.trim();

			// Сохраняем тело шаблона в AppStorage
			remoteTemplates[key][language]['templates'][tName] = tBody;
			AppStorage.set(`templates.${tName}`, tBody);

			// Удаляем шаблон из созданного элемента
			_t.parentElement.removeChild(_t);
		}

		// Извлекаем стили из созданного элемента
		const styles = Array.from(div.querySelectorAll('link[rel="stylesheet"]'))
		.filter(el => el.href)
		.map(el => {
			// Удаляем элемент стиля из созданного элемента и возвращаем его href
			el.parentElement.removeChild(el);
			return el.href;
		});

		// Если есть стили, то сохраняем и ожидаем их загрузки
		if (styles.length) {
			remoteTemplates[key][language]['styles'] = styles;

			try {
				await loadAll(styles, true, 'style');
			}
			catch (error) {
				console.error(error);
			}
		}

		let _html = div.innerHTML.trim();

		// Добавляем HTML в body документа
		if (_html) {
			remoteTemplates[key][language]['html'] = _html;

			document.body.insertAdjacentHTML('beforeend', _html);
		}

		// Добавляем ключ шаблона в список обработанных шаблонов
		__completedTemplates.push(key);
	}

	// Пытаемся закешировать шаблоны
	if ('caches' in self) {
		try {
			const cache = await caches.open(CACHE_NAME);
			const json = JSON.stringify(remoteTemplates);
			const response = new Response(json, { headers: { 'Content-Type': 'application/json' } });
			await cache.put(remoteTemplatesKey, response);
		}
		catch (e) {
			if (jsConfig['debugMode']) {
				console.info('templatesCache → saveRemoteTemplates:', e);
			}
		}
	}

	// В конце сохраняем все обновленные шаблоны в локальное хранилище
	AppStorage.set(remoteTemplatesKey, remoteTemplates);
}