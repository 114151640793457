import {childList, intersection} from "@common/observer";
import Select from "@components/Select";
import RatingStars from "@components/RatingStars";
import ProductItem from "@components/ProductItem";
import {generateVendorLogo} from "@common/generateVendorLogo";


export default class DomElementsInit {
	#targets;
	#observer = true;

	constructor(type, targets, observer) {
		this.#init(type, targets, observer);
	}

	#init = (type, targets, observer) => {
		this.#targets = targets;

		if (typeof observer === 'boolean') {
			this.#observer = observer;
		}

		switch (type){
			case 'select': {
				this.#selectInit();
				break;
			}
			case 'rating': {
				this.#ratingInit();
				break;
			}
			case 'lazyImages': {
				this.#lazyImages();
				break;
			}
			case 'productItem': {
				this.#productItem();
				break;
			}
			default: {
				break;
			}
		}
	}

	/*--------------------------------------------------------------
	Инициализация селектов
	---------------------------------------------------------------*/
	#selectInit = () => {
		if (Array.isArray(this.#targets)) {
			/*--------------------------------------------------------------
			Инициализация селектов которые есть в доме
			---------------------------------------------------------------*/
			this.#targets.forEach((selector) => {
				document.querySelectorAll(selector).forEach((el) => {
					if (!this[selector] || !Array.isArray(this[selector])) {
						this[selector] = [];
					}

					this[selector].push( new Select(el) );
				});
			});

			/*--------------------------------------------------------------
			Наблюдать за появлением новых селектов
			---------------------------------------------------------------*/
			if (this.#observer) {
				childList(document, this.#targets, (results) => {
					if (results) {
						this.#targets.forEach((selector) => {
							if (results[selector]) {
								results[selector].forEach(el => {
									if (!this[selector] || !Array.isArray(this[selector])) {
										this[selector] = [];
									}

									this[selector].push( new Select(el) );
								});
							}
						});
					}
				});
			}
		}
		else {
			console.error('DomElementsInit: #selectInit - input "targets" must be an Array!');
		}
	};

	/*--------------------------------------------------------------
	Инициализация звезд рейтинга
	---------------------------------------------------------------*/
	#ratingInit = () => {
		if (Array.isArray(this.#targets)) {
			this.all = [];

			/*--------------------------------------------------------------
			Инициализация элементов которые есть в доме
			---------------------------------------------------------------*/
			this.#targets.forEach(selector => {
				document.querySelectorAll(selector).forEach(el => {
					this.all.push( new RatingStars(el) );
				});
			});

			/*--------------------------------------------------------------
			Слежение за изменением атрибутов
			---------------------------------------------------------------*/
			if (this.#observer) {
				childList(document, this.#targets, (results) => {
					if (results) {
						this.#targets.forEach((selector) => {
							if (results[selector]) {
								results[selector].forEach(el => {
									let updated = this.all.some(instance => {
									    if (instance.el === el) {
											instance.update()
											return true;
										}
										return false;
									});

									if (!updated) {
										this.all.push( new RatingStars(el) );
									}
								});
							}
						});
					}
				});
			}
		}
		else {
			console.error('DomElementsInit: #ratingInit - input "targets" must be an Array!');
		}
	};
	
	/*--------------------------------------------------------------
	Отложенная загрузка картинок
	---------------------------------------------------------------*/
	#lazyImages = () => {
		if (Array.isArray(this.#targets)) {
			this.all = [];

			/*--------------------------------------------------------------
			Инициализация lazyImages которые есть в доме
			---------------------------------------------------------------*/
			this.#targets.forEach(selector => {
				document.querySelectorAll(selector).forEach(el => {
					setIntersectionObserver(el);
				});
			});

			/*--------------------------------------------------------------
			Слежение за появлением lazyImages
			---------------------------------------------------------------*/
			if (this.#observer) {
				childList(document, this.#targets, (results) => {
					if (results) {
						this.#targets.forEach((selector) => {
							if (results[selector]) {
								results[selector].forEach(el => {
									setIntersectionObserver(el);
								});
							}
						});
					}
				});
			}
		}
		else {
			console.error('DomElementsInit: #lazyImages - input "targets" must be an Array!');
		}
		
		/*--------------------------------------------------------------
		Инициализация обсервера
		---------------------------------------------------------------*/
		function setIntersectionObserver(el){
			intersection(el, document, (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						let src = entry.target.getAttribute('data-lazy');
						let generateVendor = entry.target.getAttribute('data-generate-vendor');

						if (generateVendor) {
							entry.target.src = generateVendorLogo(generateVendor, 256);
							entry.target.removeAttribute('data-generate-vendor');
						}
						else if (src) {
							entry.target.src = src;
						}

						entry.target.removeAttribute('data-lazy');

						observer.disconnect();
					}
				});
			});
		}
	};

	/*--------------------------------------------------------------

	---------------------------------------------------------------*/
	#productItem = () => {
		if (Array.isArray(this.#targets)) {
			/*--------------------------------------------------------------
			Инициализация элементов которые есть в доме
			---------------------------------------------------------------*/
			this.#targets.forEach(selector => {
				document.querySelectorAll(selector).forEach(el => {
					new ProductItem(el);
				});
			});

			/*--------------------------------------------------------------
			Слежение за изменением атрибутов
			---------------------------------------------------------------*/
			if (this.#observer) {
				childList(document, this.#targets, (results) => {
					if (results) {
						this.#targets.forEach((selector) => {
							if (results[selector]) {
								results[selector].forEach(el => {
									if (!el.__ProductItem) {
										new ProductItem(el);
									}
								});
							}
						});
					}
				});
			}
		}
		else {
			console.error('DomElementsInit: #productItem - input "targets" must be an Array!');
		}
	};

}