/*--------------------------------------------------------------
Импорт модулей
---------------------------------------------------------------*/
import {isEmpty} from '@common/common';
import {getVal, nestedExist} from '@common/object';
import {removeItem} from '@common/array';
import {get as getCookie} from '@common/cookies';
import GoogleRecaptcha from '@common/GoogleRecaptcha';
import * as templatesCache from "@common/templatesCache";
import md5 from 'md5';
import AppStorage from "@common/AppStorage";
import { v4 as uuidv4 } from 'uuid';


/*--------------------------------------------------------------
Название кешей
---------------------------------------------------------------*/
const CACHE_NAME    = __CACHE.name.default + (__DEV ? 'DEV-' + Math.floor(Date.now() / 1000) : __CACHE.version);
const SW_CACHE_NAME = __CACHE.name.sw + (__DEV ? 'DEV' : __CACHE.version);


/*--------------------------------------------------------------
Request urls stack
---------------------------------------------------------------*/
let _requestStack = [];

/*--------------------------------------------------------------
Очистка устаревших кешей
---------------------------------------------------------------*/
(() => {
    if ('caches' in self) {
        self.caches.keys()
        .then(cacheNames => {
            return Promise.all(
                cacheNames.map(cacheName => {
                    if (cacheName === CACHE_NAME || cacheName === SW_CACHE_NAME) {
                        return null
                    }
                    return self.caches.delete(cacheName)
                })
            )
        })
        .catch(e => {
            if (jsConfig['debugMode']) {
                console.info('CacheStorage:', e);
            }
        });
    }
})();

/*--------------------------------------------------------------
Default API callback
---------------------------------------------------------------*/
const defaultAPICallback = async data => {
    /*--------------------------------------------------------------
    Кешируем шаблоны полученные в запросе
    ---------------------------------------------------------------*/
    if (nestedExist(data, 't')) {
        await templatesCache.saveRemoteTemplates(data['t']);
    }

    /*--------------------------------------------------------------
    Регистрация событий для Google Analytics и Meta Pixel
    ---------------------------------------------------------------*/
    if (nestedExist(data, 'a')) {
        if (getVal(jsConfig, 'googleApi.analytics.enabled')) {
            const gtag = AppStorage.get('gtag');
            const googleEvents = getVal(data, 'a.google', []);

            if (typeof gtag === 'function' && googleEvents.length) {
                googleEvents.forEach(google => {
                    gtag("event", google.event, google.data || {});
                });
            }
        }

        if (getVal(jsConfig, 'metaApi.pixel.enabled')) {
            const fbq = AppStorage.get('fbq');
            const metaEvents = getVal(data, 'a.meta', []);

            if (typeof fbq === 'function' && metaEvents.length) {
                metaEvents.forEach(meta => {
                    fbq("track", meta.event, meta.data || {});
                });
            }
        }
    }

    /*--------------------------------------------------------------
    Смена location
    --------------------------------------------------------------*/
    if (nestedExist(data, 'l')) {
        window.location = data['l'] === 'refresh' ? window.location : data['l'];
    }

    /*--------------------------------------------------------------
	Отображение сообщения от сервера
	---------------------------------------------------------------*/
    if (nestedExist(data, 's', 'm') && !data['s']){
        /*--------------------------------------------------------------
		Import PageMessage global instance
		---------------------------------------------------------------*/
        let pageMessage = AppStorage.get('pageMessage');

        let msg = String(data['m']),
            code = String(data['e'])[0],
            type = code === '1' ? 'success' : (code === '2' ? 'warning' : (code === '3' ? 'danger' : ''));

        /*--------------------------------------------------------------
		if got an array with messages, data['ml'] - must be an array
		---------------------------------------------------------------*/
        if (data['ml'] && Array.isArray(data['ml']) && data['ml'].length) {
            msg += '<ul>';
            msg += data['ml'].reduce((str, ml) => str + '<li>' + ml + '</li>', '');
            msg += '<ul>';
        }

        if (pageMessage) {
            pageMessage.show(msg, type);
        }
    }
}

/*--------------------------------------------------------------
API request
---------------------------------------------------------------*/
export function get(url = null, settings = {}) {
    if (!url || typeof url !== 'string') {
        return new Promise(() => {
            if (jsConfig['debugMode']) {
                console.error('API.get: need to set URL');
            }

            throw new Error();
        });
    }

    let dataObj = settings['data'] || {},
        sender = settings['sender'],
        defaultCallback = typeof settings['defaultCallback'] === 'boolean' ? settings['defaultCallback'] : true,
        preloader = typeof settings['preloader'] === 'boolean' ? settings['preloader'] : true,
        recaptcha = typeof settings['recaptcha'] === 'boolean' ? settings['recaptcha'] : false,
        consoleEnabled = typeof settings['console'] === 'boolean' ? settings['console'] : true,
        retry = typeof settings['retry'] === 'number' ? settings['retry'] : 0,
        requestData = AppStorage.get('requestData'),
        uid = settings['uid'] ? (typeof settings['uid'] === 'string' ? settings['uid'] : uuidv4()) : null;


    /*--------------------------------------------------------------
	Concat dataObj with requestData from AppStorage
	---------------------------------------------------------------*/
    if (typeof requestData === 'object') {
        for (let k in requestData) {
            if (requestData.hasOwnProperty(k) && !(k in dataObj)) {
                if (typeof requestData[k] === 'object' && isEmpty(requestData[k])) dataObj[k] = '';
                else dataObj[k] = requestData[k];
            }
        }

        /*--------------------------------------------------------------
		remove requestData from AppStorage
		---------------------------------------------------------------*/
        AppStorage.remove('requestData');
    }

    /*--------------------------------------------------------------
    Добавление уникальности в адрес запроса
    --------------------------------------------------------------*/
    if (uid) {
        url += (url.includes('?') ? '&' : '?') + `uid=${uid}`;
    }

    /*--------------------------------------------------------------
    Добавление мд5 от экшена в адрес запроса
    (что бы слались параллельные запросы по одному адресу с разными экшенами)
    --------------------------------------------------------------*/
    url = typeof dataObj.action === 'string' ? url + (url.includes('?') ? '&' : '?') + `a=${md5(dataObj.action)}` : url;

    /*--------------------------------------------------------------
	Recaptcha check needed
	---------------------------------------------------------------*/
    if (recaptcha) {
        if (!AppStorage.get('recaptcha')) {
            AppStorage.set('recaptcha', new GoogleRecaptcha());
        }

        /*--------------------------------------------------------------
		Check recaptcha and call request again with current settings
		---------------------------------------------------------------*/
        return AppStorage.get('recaptcha').v3()
            .then(antiBotToken => {
                dataObj['anti_bot_token'] = antiBotToken;

                settings.data = dataObj;

                delete settings.recaptcha;

                return get.call(this, url, settings);
            });
    }

    /*--------------------------------------------------------------
	Check request duplication
	---------------------------------------------------------------*/
    if (_requestStack.indexOf(url) >= 0) return new Promise(() => null);
    _requestStack.push(url);

    /*--------------------------------------------------------------
	Show preloader
	---------------------------------------------------------------*/
    if (preloader) {
        preloaderToggle(true);
    }

    let requestStartTime = Date.now();

    return fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRF': getCookie('csrf'),
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(dataObj),
    })
        .then(response => {
            let status = response.clone().status;

            //если код ответа 307, то переотправить запрос
            if (status === 307) {
                retry += 1;
            }
            else if (status === 403) {
                return null;
            }

            return response.text()
        })
        .then(data => {
            try {
                return JSON.parse(data);
            }
            catch {
                return Promise.reject(data);
            }
        })
        .then(async data => {
            if (consoleEnabled && jsConfig['debugMode']) {
                console.log();
                console.group('%c REQUEST: %c' + (Date.now() - requestStartTime) / 1000 + 's', 'background:#000; color:#2aca3d; font-size: 14px; cursor:pointer;');
                console.log('URL: %chttps:' + url, 'color:#75bfff; text-decoration:underline;');
                console.log('Request data:', dataObj);
                if (!data) {
                    console.log('%cResponse:', 'color:#f40008;', data);
                } else {
                    console.log('Response:', data);
                }
                console.groupEnd();
            }

            _requestStack = removeItem(_requestStack, url);

            if (preloader) preloaderToggle(false);

            data = data || {};
            data = typeof data === 'object' ? data : {}

            /*--------------------------------------------------------------
			Call DefaultCallback
			---------------------------------------------------------------*/
            if (defaultCallback) {
                await defaultAPICallback.call(this, data);
            }

            if (sender) {
                data._sender = sender;
            }

            return data
        })
        .catch((e) => {
            if (consoleEnabled && jsConfig['debugMode']) {
                console.log();
                console.group('%c REQUEST ERROR: %c' + (Date.now() - requestStartTime) / 1000 + 's', 'background:#000; color:#e21d1d; font-size: 14px; cursor:pointer;');
                console.log('URL: %chttps:' + url, 'color:#75bfff; text-decoration:underline;');
                console.log('Request data:', dataObj);
                if (e) {
                    console.error(e);
                }
                console.groupEnd();
            }

            _requestStack = removeItem(_requestStack, url);

            if (preloader) preloaderToggle(false);

            /*--------------------------------------------------------------
			Retry send request again
			---------------------------------------------------------------*/
            if (retry) {
                settings.retry = retry - 1;

                return get.call(this, url, settings);
            }
            else {
                return Promise.reject(e);
            }
        });
}

/*--------------------------------------------------------------
API caching
--------------------------------------------------------------*/
export function cache(url = null, cacheOptions = {}, requestSettings = {}){
    if (!url || typeof url !== 'string') {
        return new Promise(() => {
            if (jsConfig['debugMode']) {
                console.error('API.cache: need to set URL');
            }

            throw new Error();
        });
    }

    let dataObj = requestSettings.data || {},
        uniqueID = cacheOptions.uniqueID ? md5(cacheOptions.uniqueID) : (Object.keys(dataObj).length ? md5(JSON.stringify(dataObj)) : null),
        uniqueURL = url + (uniqueID ? `?c=${uniqueID}` : ''),
        ttl = parseInt(cacheOptions.ttl, 10) || 0,
        //если requestsQueue false, то запросы летят по заданному url, иначе на uniqueURL. По умолчанию true
        requestsQueue = typeof cacheOptions.requestsQueue === 'boolean' ?  cacheOptions.requestsQueue : true;
        // localStorageEnabled = typeof cacheOptions.localStorageEnabled === 'boolean' ? cacheOptions.localStorageEnabled : true,
        // storageKey = md5(uniqueURL);


    return new Promise((__resolve, __reject) => {
        if ('caches' in self) {
            /*--------------------------------------------------------------
            В приватных вкладках Firefox - CacheStorage не работает:
            (DOMException: The operation is insecure.)
            Поэтому делаем проверку self.caches.keys() и в случае ошибки
            пропускаем этап работы с CacheStorage
            --------------------------------------------------------------*/
            self.caches.keys()
            .then(() => {
                self.caches.open(CACHE_NAME).then(cache => {
                    cache.match(uniqueURL)
                    .then(response => {
                        if (response) {
                            return response.json();
                        }
                        else {
                            return Promise.reject();
                        }
                    })
                    .then(response => {
                        let updated = parseInt(response.updated, 10) || 0,
                            expired = (Date.now() - updated) / 1000 > ttl;

                        if (expired || !response.data) {
                            return Promise.reject();
                        }
                        else {
                            if (jsConfig['debugMode']) {
                                console.log();
                                console.group( '%c CACHED: %c', 'background:#000; color:#f5e36c; font-size: 14px; cursor:pointer;');
                                console.log('URL: %chttps:' + uniqueURL, 'color:#75bfff; text-decoration:underline;');
                                console.log('Request data:', dataObj);
                                if (!response.data) {
                                    console.log('%Cached data:', 'color:#f40008;', response.data);
                                }
                                else {
                                    console.log('Cached data:', response.data);
                                }
                                console.groupEnd();
                            }

                            __resolve( {
                                result: Promise.resolve(response.data)
                            } );
                        }
                    })
                    .catch((e) => {
                        if (jsConfig['debugMode'] && e) {
                            console.error('API.cache:', e);
                        }

                        __resolve({
                            result: get.call(this, requestsQueue ? uniqueURL : url, requestSettings),
                            type: 'cacheStorage'
                        });
                    });
                });
            })
            .catch(e => {
                __resolve({
                    result: get.call(this, requestsQueue ? uniqueURL : url, requestSettings),
                });
            })
        }
        else {
            __resolve({
                result: get.call(this, requestsQueue ? uniqueURL : url, requestSettings),
            });
        }
    })
    .then(( {result, type} ) => {
        return result
            .then(data => {
                /*--------------------------------------------------------------
                Если код ответа 1 (положительный), то кешируем полученный результат
                --------------------------------------------------------------*/
                if (type && String(getVal(data, 'e'))[0] === '1') {
                    if (type === 'cacheStorage') {
                        return new Promise((resolve, reject) => {
                            self.caches.open(CACHE_NAME).then(cache => {
                                cache
                                .put(uniqueURL, new Response(JSON.stringify({
                                    data: data,
                                    updated: Date.now(),
                                })))
                                .then(() => {
                                    resolve(data);
                                });
                            });
                        });
                    }
                }

                return data
            })
    })
}

/*--------------------------------------------------------------
Прелоадер страницы
---------------------------------------------------------------*/
function preloaderToggle(toggle){
    let loader = document.querySelector('#pg-loader');

    if (!loader) {
        loader = document.createElement('div');
        loader.id = 'pg-loader';
        loader.className = '_linear';
        document.body.append(loader);
    }

    loader.classList.toggle('show', toggle);
}

/*--------------------------------------------------------------
Генерация ссылки для API запроса
---------------------------------------------------------------*/
export function url(requestName, section){
    return '//' + (typeof section === 'string' && section.length ? section + '.' : '') + getVal(jsConfig, 'url.base_domain', '') + '/' + getVal(jsConfig, 'url.language', '') + '/api/' + requestName;
}