/*--------------------------------------------------------------
Импорт модулей
---------------------------------------------------------------*/
import {childList} from "@common/observer";



/**
 * @description Преобразование любого типа "target" в массив элементов
 * @param target - string selector, node element, nodeList, jquery object
 * @return Array of node elements
 */
export function getElementsArray (target) {
	if (target) {
		if (typeof target === 'string') {
			return find(target)
		}
		else if (target.nodeType) {
			if (target.nodeType === 1) {
				return [target]
			}
			else if (target.nodeType === 9) {
				return [document.documentElement]
			}

			return []
		}
		else {
			return [].slice.call(target)
		}
	}

	return [];
}


/**
 * @description Поиск элементов в доме / в родительском элементе
 * @param childSelectors - string selectors
 * @param parent - string selector, node element, nodeList, jquery object
 * @param handler - not required handler function
 * @return Array of node elements
 */
export function find (childSelectors, parent, handler) {
	let arr = [];

	if (typeof childSelectors === 'string') {
		parent = parent ? getElementsArray(parent) : [document];

		childSelectors = childSelectors.split(',').map(s => s.trim());

		for (let i = 0; i < parent.length; i++) {
			for (let j = 0; j < childSelectors.length; j++) {
				arr = arr.concat( [].slice.call(parent[i].querySelectorAll(childSelectors[j])) );
			}
		}
	}

	if (typeof handler === "function") {
		for (let i = 0; i < arr.length; i++) {
			handler.call(this, arr[i], i);
		}
	}

	return arr
}


/**
 * @description аналог ".last()" в jQuery
 * @param childSelector - string selector
 * @param parent - string selector, node element, nodeList, jquery object
 * @return last element or undefined
 */
export function findLast(childSelector, parent){
	let results = find(childSelector, parent);

	return results[ results.length - 1 ]
}


/**
 * @description все предыдущие одинаковые, смежные соседи
 * @param el - node element
 * @return array of node elements
 */
export function prevAll (el) {
	let prevAll = true;

	return [].filter.call(el.parentNode.children, htmlElement => htmlElement === el ? prevAll = false : prevAll);
}


/**
 * @description аналог ":visible" в jQuery, поиск видимых элементов
 * @param child - string selector
 * @param parent - string selector, node element, nodeList, jquery object
 * @return last element or undefined
 */
export function findVisible(child, parent){
	return find(child, parent).filter(el => isVisible(el));
}


/**
 * @description все соседи, если указан селектор то определенные соседи
 * @param el - node element
 * @param selector - string selector
 * @param onlyDirectChildren - (boolean) - ищет только среди только прямых потомков
 * @return array of node elements
 */
export function siblings (el, selector, onlyDirectChildren) {
	if (!el) return [];

	el = getElementsArray(el);

	if (typeof selector === 'string') {
		if (onlyDirectChildren) {
			selector = ':scope > ' + selector;
		}

		return el.reduce((arr, _el) => {
			return arr.concat(find(selector, _el.parentNode).filter(node => node !== el));
		}, [])

		// return find(selector, el.parentNode).filter(node => node !== el);
	}
	else {
		return el.reduce((arr, _el) => {
			return arr.concat( [].filter.call(el.parentNode.children, node => node !== el) );
		}, [])

		// return [].filter.call(el.parentNode.children, node => node !== el);
	}
}


/**
 * @description аналог ".next() jQuery"
 * @param el - node element
 * @param selector - string selector
 * @return node element or null
 */
export function next (el, selector) {
	// let nextElem = el.nextElementSibling;
	//
	if (typeof selector !== 'string') {
		return null;
	}
	//
	// if (nextElem && nextElem.matches(selector)) {
	// 	return nextElem;
	// }
	//
	// return null;
	let elIndex = index(el);

	return siblings(el, selector, true).reduce((next, _el) => {
	    if (!next && elIndex < index(_el)) {
			next = _el
		}

		return next;
	}, null);
}


/**
 * @description проверка NodeList коллекции
 * @return boolean
 */
export function isNodeList(collection){
	return NodeList.prototype.isPrototypeOf(collection) || HTMLCollection.prototype.isPrototypeOf(collection);
}


/**
 * @description проверка наличия класса у элемента
 * @return boolean
 */
export function hasClass(element, elClass){
	return element && element.classList && element.classList.contains(elClass)
}


/**
 * @description проверка текущий набор элементов с селектором
 * @return boolean
 */
export function is(targets, selector){
	return getElementsArray(targets).some(node => node.matches(selector))
}


/**
 * @description аналог ".isVisible()" и ":visible"-селектор в jQuery
 * @param el - target node element(s)
 * @return boolean
 */
export function isVisible(el){
	return el.offsetWidth > 0 || el.offsetHeight > 0
}


/**
 * @description аналог ".toggle()" в jQuery
 * @param target - target node element(s)
 * @param toggler - not required
 * @param displayType - string (flex, block, inline, etc.) | default block
 */
export function toggle(target, toggler, displayType = 'block'){
	getElementsArray(target).forEach(el => {
		if (typeof toggler === 'undefined') {
			toggler = !isVisible(el);
		}

	    el.style.display = !!toggler ? displayType : 'none';
	});
}



/**
 * @description аналог ".trigger()" в jQuery
 * @param el - target node element(s)
 * @param event - string
 */
export function trigger(el, event){
	if (typeof event === 'string') {
		if (el === window) {
			window.dispatchEvent( new CustomEvent(event) );
		}
		else {
			getElementsArray(el).forEach(_el => {
				_el.dispatchEvent( new CustomEvent(event) );
			});
		}
	}
}


/**
 * @description аналог ":contains" в jQuery
 * @param targetEl 			- node element
 * @param childSelector 	- string
 * @param compareValue 		- compare value
 * @param handler 			- not required handler function
 * @return array with filtered elements which contains input value
 */
export function findContains(targetEl, childSelector, compareValue = '', handler){
	targetEl = getElementsArray(targetEl)[0];

	if (targetEl && typeof childSelector === 'string') {
		handler = typeof handler === "function" ? handler : null;

		compareValue = String(compareValue).toLowerCase();
		/*--------------------------------------------------------------
		Экранирование специальных символов
		--------------------------------------------------------------*/
		compareValue = compareValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

		return find(childSelector, targetEl).filter(el => {
			let isContains = !compareValue ? true : RegExp(compareValue).test(el.textContent.toLowerCase());

			if (handler) handler.call(this, el, isContains);

			return isContains
		})
	}

	return []
}


/**
 * @description аналог ".offset()" в jQuery
 * @param targetEl - target node element
 * @return offsets object
 */
export function offset(targetEl){
	let offset = {
			top: window.scrollY,
			left: window.scrollX,
		};

	if (targetEl) {
		let rect = targetEl.getBoundingClientRect();

		offset.top += rect.top;
		offset.left += rect.left;
		offset.right = rect.right;
		offset.bottom = rect.bottom;
	}

	return offset
}


/**
 * @description аналог ".index()" в jQuery
 * @param targetEl - target node element
 * @return element index
 */
export function index(targetEl){
	return targetEl ? [].slice.call(targetEl.parentNode.children).indexOf(targetEl) : 0
}


/**
 * @description аналог ".parents()" в jQuery
 * @param el - target node element
 * @param selector - parent selector
 * @return elements array
 */
export function parents(el, selector){
	const parents = [];

	while ((el = el.parentNode) && el !== document) {
		if (!selector || el.matches(selector)) parents.push(el);
	}

	return parents;
}


/**
 * @description аналог ".on()" в jQuery
 * @param targetEl - 		(str|node) 	target element or selector
 * @param events - 			(str) 		events names with spaces
 * childSelectors - 		(str) 		target children selector (multiple)
 * callback - 				(function) 	callback function
 * options - 				(object) 	event options
 */
export function event(targetEl, events){
	let args = [...arguments],
		childSelectors = typeof args[2] === 'string' ? args[2] : null,
		callback = typeof args[2] === 'function' ? args[2] : (typeof args[3] === 'function' ? args[3] : null),
		options = typeof args[args.length - 1] === 'object' ? args[args.length - 1] : {};

	if (typeof events === 'string' && typeof callback === 'function') {
		events.split(' ').forEach(event => {
			getElementsArray(targetEl).forEach(target => {
				if (childSelectors) {
					childSelectors = String(childSelectors).split(',').map(s => s.trim());

					if (childSelectors.length) {
						childSelectors.forEach(selector => {
							find(selector, target).forEach(el => {
								el.addEventListener(event, callback, options)
							});
						});

						childList(target, childSelectors, (results) => {
							if (results) {
								childSelectors.forEach(selector => {
									if (results[selector]) {
										results[selector].forEach(el => {
											el.addEventListener(event, callback, options)
										});
									}
								});
							}
						});
					}
				}
				else {
					target.addEventListener(event, callback, options);
				}
			});
		});
	}
}