/*--------------------------------------------------------------
Разборка JSON в объект
---------------------------------------------------------------*/
export function jsonToObj(json) {
    try {
        return JSON.parse(json.replace(/'/g, '"'));
    }
    catch (e) {
        if (jsConfig['debugMode']) console.error('jsonToObj:', json, '\nJSON.parse error!');
        return {};
    }
}

/*--------------------------------------------------------------
Проверка пути к объекту
---------------------------------------------------------------*/
export function nestedExist(obj, ...args) {
    if (!args.length) return false;

    return args.every(arg => {
        let _o = obj;

        try {
            const path = arg.split('.');

            for (const prop of path) {
                if (!(prop in _o)) {
                    return false;
                }
                _o = _o[prop];
            }

            return true;
        }
        catch (e) {
            return false;
        }
    });
}

/*--------------------------------------------------------------
Получение значение объекта по указанному пути,
если не найдено, то стандартное значение.
+ добавлено: возвращаемый тип данных должен быть таким же,
  как и тип defaultValue
---------------------------------------------------------------*/
export function getVal(obj, path, defaultValue = null) {
    if (!obj || typeof obj !== "object") return defaultValue;

    if (!path) return obj;

    const value = path.split(".").reduce((acc, key) => acc?.[key], obj);

    if (value === null || value === undefined) {
        return defaultValue;
    }

    if (Array.isArray(defaultValue)) {
        if (!Array.isArray(value)) {
            return defaultValue;
        }

        return value;
    }

    if (typeof defaultValue === "object" && defaultValue !== null) {
        if (typeof value !== "object" || Array.isArray(value)) {
            return defaultValue;
        }

        return value;
    }

    if (typeof defaultValue === "number") {
        return Number(value) || 0;
    }

    if (typeof defaultValue === "string") {
        return String(value);
    }

    if (typeof defaultValue === "boolean") {
        return Boolean(value);
    }

    return value;
}

/*--------------------------------------------------------------
Перебор объекта
---------------------------------------------------------------*/
export function forIn(obj, handler) {
    if (typeof obj === 'object' && typeof handler === 'function') {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                handler.call(this, obj[key], key);
            }
        }
    }
}