export function get(name) {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function set(name, value, days, domain) {
    let expires;

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }

    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + ";Secure;SameSite=none; path=/;domain=" + (domain || location.host);
}

export function remove(name) {
    set(name, '', -1);
}

/*--------------------------------------------------------------
Установка Cookies из конфига
--------------------------------------------------------------*/
export function preset(config = {}) {
    /*--------------------------------------------------------------
    Set time zone
    --------------------------------------------------------------*/
    set('CTZO', (-(new Date().getTimezoneOffset()) * 60), 365);

    /*--------------------------------------------------------------
	Set client last success url (CLSU)
	---------------------------------------------------------------*/
    if ( Array.isArray(config['clsuExclude']) ) {
        let _url = location.href.split('?')[0],
            _result = config['clsuExclude'].every(ex => !_url.includes(ex));

        if (_result) set('CLSU', _url, 1, '.' + location.host.split('.').splice(-2).join('.'));
    }
    else {
        set('CLSU', (location.href.split('?')[0]), 1, '.' + location.host.split('.').splice(-2).join('.'));
    }

    /*--------------------------------------------------------------
	Unset all cookies which got in config
	---------------------------------------------------------------*/
    if ( Array.isArray(config['unset']) ) {
        config['unset'].forEach(cookie => {
            remove(cookie);
        });
    }
}