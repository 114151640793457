import {getVal} from "@common/object";


const CACHE_NAME = __CACHE.name.default + (__DEV ? 'DEV-' + Math.floor(Date.now() / 1000) : __CACHE.version);

/*--------------------------------------------------------------
Очистка устаревших кешей
---------------------------------------------------------------*/
(() => {
    if (isSupported()) {
        Object.keys(localStorage).forEach(key => {
            if (key !== CACHE_NAME) {
                delete localStorage[key];
            }
        });

        checkLocalStorageSizeAndClear();
    }
})();

/*--------------------------------------------------------------
Получение данных из localStorage[CACHE_NAME]
---------------------------------------------------------------*/
export function get(key) {
    if (!isSupported()) return false;

    if (localStorage[CACHE_NAME] !== undefined) {
        try {
            return getVal(JSON.parse(localStorage[CACHE_NAME]), key, null);
        }
        catch (e) {
            return null;
        }
    }
    else return null;
}

/*--------------------------------------------------------------
Сохранение данных в localStorage[CACHE_NAME]
---------------------------------------------------------------*/
export function set(key, data) {
    if (!isSupported()) return false;

    let obj;

    try {
        obj = JSON.parse(localStorage[CACHE_NAME]);
    }
    catch (e) {
        obj = {};
    }

    obj[key] = data;

    localStorage[CACHE_NAME] = JSON.stringify(obj);

    return true;
}

/*--------------------------------------------------------------
Checking if HTML5 local storage is supported
---------------------------------------------------------------*/
export function isSupported() {
    try {
        return 'localStorage' in window && window['localeStorage'] !== null;
    }
    catch (e) {
        console.error("Exception message (localStorage.isSupported):\n" + e.message);
        return false;
    }
}

/*--------------------------------------------------------------
Calculate used local storage total size
---------------------------------------------------------------*/
export function getUsedSize() {
    let _lsTotal = 0,
        _xLen, _x;

    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
            continue;
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2);
        _lsTotal += _xLen;
        console.log(_x.substring(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
    }

    console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
}


/*--------------------------------------------------------------
Clear localStorage when its full
--------------------------------------------------------------*/
function checkLocalStorageSizeAndClear() {
    const maxSize = 4.99 * 1024 * 1024; // 5MB maximum size
    const currentSize = JSON.stringify(localStorage).length;

    if (currentSize >= maxSize) {
        localStorage.clear();
    }
}